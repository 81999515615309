import React from 'react';
import Logo from '../assets/header-web.png';
import { BrowserRouter as Router, Routes, Link, Route } from 'react-router-dom';
import './navbar.css';

function Navbar() {
    return (
        <div className='navbar'>
            <div className='banner'>
                <img src={Logo} alt='dakiLogo' className='dakiLogo' />
            </div>
            <div className='links'>
                <nav>
                
             {
             <><button className='buttonStyle'> <Link to="/" className='linkButton'>Home</Link></button>
             <button className='buttonStyle'> <Link to="/registration" className='linkButton'>Registration</Link></button></>
             
             /* <button className='buttonStyle'> <Link to="/food" className='linkButton'>Food?</Link></button>  

                <button className='buttonStyle'> <Link to="/trivia" className='linkButton'>Trivia</Link></button>

                <button className='buttonStyle'> <Link to="/rules" className='linkButton'>Beyblade</Link></button>
                    
                <button className='buttonStyle'><Link to="/" className='linkButton'>FAQ </Link></button> */}
            
                    
                </nav>
            </div>
        </div>
    );
}

export default Navbar


/*







<button className='buttonStyle'><Link to="/gallery" className='linkButton'>Gallery </Link></button>

                    
            */