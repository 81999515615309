import React, { useState, useRef, useCallback} from 'react';
import styled from 'styled-components';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TableDatePicker from './datePicker';
import ReCAPTCHA from "react-google-recaptcha";
import './registration.css';


/*const Form = () => {
    const formRef = useRef(null)
    const scriptUrl = "https://script.google.com/macros/s/AKfycbwpOp2nYbd1mTMqXsw4iezvAa521NoxSQJ1gd703iNYeL-ifKL9iFx1oNCQB5CRNRLl/exec"
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        fetch(scriptUrl, {
            method: 'POST',
            body: new FormData(formRef.current),

        }).then(res => {
            console.log("SUCCESSFULLY SUBMITTED")
            setLoading(false)
        })
            .catch(err => console.log(err))
    }
*/
export default function Form() {

    function Submit(e) {
        const formEle = document.querySelector("form");
        const formDatab = new FormData(formEle);
        fetch(
            "https://script.google.com/macros/s/AKfycbxz5voGxLGraC_YAVe5gFxTTKweF_Qy4GHPfUJOk3YcbVznDMJBVvoxSg2O0Zpu7dsV/exec",
            {
                method: "POST",
                body: formDatab
            }
        )
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });
            alert("Thank you for submitting your form");
            
    }
    const [capVal, setCapVal] = useState();
    const [showButton, setShowButton] = useState(false);
    const toggleButton = () => {
        setShowButton(showButton);
      };

    return (

        <FormStyle>

            <div className="container">

                <form className='form' name="form" /*ref={formRef} onSubmit={handleSubmit}*/ onSubmit={(e) => Submit(e)}>
                    <div className="input-style">
                        <label htmlFor='Name'>
                            Link to your Instagram or Facebook
                        </label>
                        <input type="text" id="Name" name="Name" placeholder='https://www.instagram.com/your.instagram/' required/>
                    </div>
                    <div className="input-style">
                        <label htmlFor='Email'>Email</label>
                        <input type="email" name="Email" placeholder='yournick@gmail.com' required />
                    </div>
                    <div className="input-style">
                        <label htmlFor='Age'>Date of birth</label>
                        <input type="text" name="Age" placeholder='1994-01-01' required/>
                    </div>
                    <div className="input-style">
                        <label htmlFor='Recommendation'>Please post link to your recommendation (Imgur, Google Drive, whatever works)</label>
                        <input type="text" name="Recommendation" placeholder='https://imgur.com/t/body_pillow/LGVTe1v' required/>
                    </div>
                    <div className="input-style">
                        <label htmlFor='Message'>How did you learn about us? Is there anything else you would like to add?</label>
                        <input type="text" name="Message" placeholder='Please let us know!' required/>
                    </div>

                    <div className="input-style">
                   
                         
                        <input type="submit" className='submitButton'  /*value={loading ? "Loading..." : "Register"}*/ />

                    </div>
                </form>
            </div>
        </FormStyle>
    )
}
/*     <div className='recaptcha'>
                        <ReCAPTCHA sitekey='6LeoOBApAAAAAN7xMW07yU5nsi8jRLWyTtxnMykG'
                         onChange={(val) => setCapVal(val)} 
</div>
                         disabled={!capVal} hidden={!capVal}
                         /> */

const FormStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .container{
        background-color: #282c34;
        padding: 5rem 10rem 10rem 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        @media(max-width: 610px){
            padding: 0.5rem 4rem 4rem 4rem;
        }

        .input-style{
            padding-top: 0.8em;
            display: flex;
            flex-direction: column;
            gap: 0.8em;

            label{
                font-family: 'Poppins', sans-serif;
                color: #faebd7;
            }

            input{
                outline: none;
                
                padding: 0.8em;
                border-radius: 2em;
                color: #faebd7;
            }
            input[type=submit]{
                background-color: #BC002D;
                color: #FFFFFFFF;
                font-weight: bold;
                
                }
           

            }
        
        }

    }

`
