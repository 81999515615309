import React from 'react'
import './rules.css';

export default function triviaRules() {
    return (
        <div className='ruleList'>

            <p className='triviaTop'>Anime music trivia will commence later in the evening and will have some <b>exclusive</b> rewards!</p>
            <ol>
                <li>
                    Register for trivia at the event!
                </li>
                <li>
                    4 players total, two teams. First come, first served.
                </li>
                <li>
                    There will be 15 regular rounds, scaling in difficulty. Then 3 betting rounds, more details below.
                </li><li>
                    During regular rounds, you will hear the start of a song for 10-20 seconds, then make a guess. If neither team guesses correctly, there will be a hint.
                </li><li>
                    Questions 1-10 will reward 2 points before hint and 1 point after. Questions 11-15 will provide 3 points before the hint, 2 after.
                </li><li>
                    In betting rounds, you can bet any amount of points that does not put you below 3. If you answer the question, you will double your points! Otherwise you will lose them.
                </li>
                <li>
                    Rules will be explained before the trivia and if needed during the trivia. Don't be shy to ask if something isn't clear!
                </li>
            </ol>
        </div>

    )
}