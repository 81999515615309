import React from 'react'
import './faq.css';

export default function Faq() {
    return (

        <div className='faqList'>


            <ul>
                <h2>GENERAL QUESTIONS</h2>

                <li>
                    Q: Who can attend?

                </li>
                A: Anybody who is over 18, got and accepted the Dakimakura Club mailing list.
                <li>
                    Q: Must my cosplay be related to space and/or pirates?

                </li>
                A: No, but we'd appreciate if you help to keep up the atmosphere.
                <li>
                    Q: Can I come without a cosplay?
                </li>
                A: Yup

                <li>
                    Q: Can I just come to chill on the sofa instead of singing?
                </li>
                A: Sure!

                <li>
                    Q: Can I come to the venue earlier to change into my cosplay?
                </li>
                A: Unfortunately, the venue only opens at 17:00. We will take about 30 minutes to set up and for everybody to gather, so if you can't come in costume, you can get changed then.

                <li>
                    Q: Are drinks/snacks available at the venue?
                </li>
                A: Yup, SING has their own bar. However, if you are below legal drinking age (20) and we catch you with an alcoholic beverage - both you and the person who bought it for you will be asked to leave immediately. This goes for other illegal activities as well.

                <li>
                    Q: Will there be an official after party?
                </li>
                A: Sorry, not this time! If you will still have energy to party after 6 hours of karaoke... Godspeed, space cowboy.

                <li>
                    Q: I have more questions, who can I ask?
                </li>
                A: You are welcome to contact us on Instagram (@dakimakurainc), Facebook (Dakimakura Inc.) or via email (dakimakurainc@gmail.com)

                <h2>TICKETS AND PRICING</h2>
                <li>
                    Q: Can I get a discount?
                </li>
                A: Yes, you can get a small discount if you come in cosplay
                <li>
                    Q: No discounts for singing this time? :(
                </li>
                A: You guys don't need encouragement to sing anymore :) But you can always throw on a wig an a pair of cat ears.

                <li>
                    Q: How do I get discounted cosplay ticket?
                </li>
                A: Enter discount code COSPLAY when buying your ticket

                <li>
                    Q: What will happen if I buy a ticket with cosplay discount but decide to show up without a costume?
                </li>
                A: You will have to pay the extra 5 EUR at the entrance.
                <li>
                    Q: I no longer plan to attend, can you refund my ticket?
                </li>
                A: Contact us via dakimakurainc@gmail.com, we should get back at you in 1-3 business days. No refunds will be issued starting January 20th.

                <h2>SONG INFO</h2>
                <li>
                    Q: Must my song be anime related?
                </li>
                A: Nope, from Cicinas to Baby Metal - sing whatever you want.
                <li>
                    Q: How many people can sing together?
                </li>
                A: There are two mics available for singing and the stage area is not very big. If you can work around it with more people, you are more than welcome.
                <li>
                    Q: Can I just come and sing karaoke without registering first?
                </li>
                A: Of course! But when you pre-reg your song, it allows us to prepare the playlist in advance.
                <li>
                    Q: How do I register a song?
                </li>
                A: We will inform you via email when the song registration will open - you will find the details there.

            </ul>
        </div>
    )
}