import React from 'react'
import './rules.css';

export default function Rules() {
    return (
        <div className='ruleList'>
            <ol>
                <li>
                    8 players total. First come, first served.
                </li>
                <li>
                    The tournament will follow tournament bracket/tournament tree format, also known as single elimination, with the 8 registered players assigned their positions randomly.
                </li>
                <li>
                    “Best of three” format. You will be facing your opponent in a match of 3 battles. You have to score at least 2 points out of 3 to win the match.
                </li>
                <li>
                    Spin-out victory earns you 1 point. Ring-out finish nets you 1 point. However...
                </li>
                <li>
                    Burst finish awards 3 points instantly. If you manage to burst your opponent's Beyblade, no matter how many points they have, you instantly win the match.
                </li>
                <li>
                    Each player has 1 flub reset per tournament. If you flub your launch, you can request a reset, but only once.
                </li>
                <li>
                    In the spirit of sportsmanship and intrigue, it is highly recommended to bring your own Beyblades.
                    You are allowed to modify them. However, each Beyblade will be inspected before participating. For the reasons of good time and fun, no one would like to get their Beyblade broken, so please keep that in mind while working on your top. Because of that, full-metal, full-wood or similar "dangerous" tops are not allowed. Recommended maximum total weight of your Beyblade is around 85-90 grams.
                    As for the launcher, the same rules apply. You can modify your launcher, but it will be inspected.
                </li>
                <li>
                    If you do not have a Beyblade, or yours is disqualified, you can borrow one from Dakimakura Inc collection. There will be several Beyblades for you to borrow and compete with.
                    Only the first and the second place are eligible for prizes. The first place gets the aforementioned reserved spot and discount for the next karaoke event. The second place gets a consolation prize.*
                </li>
                <li>
                    Having a dramatic backstory and intro is highly recommended.
                </li>
            </ol>
            <p className='letItRip'>NOW GO PREPARE TO LET IT RIP!!!</p>



            <footer>*Winning the first place prize does not guarantee you a placement on the Dakimakura Inc. invitation list. If you, regrettably, lose your placement on the invitation list, your prize becomes invalid too.
                The first place prize also entails a right to reservation to a +1 of your choosing, provided the person is on the Dakimakura Inc. invitation list.
                The first place prize can only be transferred to any other guest only in the cases of force majeure and the transfer must be discussed with the Dakimakura Inc. team. Giving away the prize as a gift without notifying the Dakimakura Inc. team members and their explicit consent of the prize transfer will render the prize invalid, and the receiving person and their +1 will not be able to purchase the ticket.</footer>
        </div>

    )
}