import React from 'react'
import './faq.css';
import mapsIcon from '../assets/maps_icon.png';


export default function Food() {
    return (

        <div className='faqList'>
            <p className='foodIntro'>
                The event is gonna last 6 hours and you can only buy snacks at the event, so it is a good idea to fuel up! Click on the google maps icon to go straight to google maps.
            </p>
            <ul className='foodList'>
                <li>
                    <a href="https://maps.app.goo.gl/bRWgQwcrnvVHdfdt6"><img src={mapsIcon} alt='maps_icon' className='mapsIcon'></img></a> Radharanė - a vegetarian place, open 11:00-20:00
                </li>
                <li>
                    <a href="https://maps.app.goo.gl/wyBLF3ASFp7JnTGy9"><img src={mapsIcon} alt='maps_icon' className='mapsIcon'></img></a> Drama burger - a burger place, open 11:00-22:00
                </li>
                <li>
                    <a href="https://maps.app.goo.gl/4FwscDhrTUv3XL5V6"><img src={mapsIcon} alt='maps_icon' className='mapsIcon'></img></a> McDonalds - do you really need me to elaborate?
                </li>
                <li>
                    <a href="https://maps.app.goo.gl/13wjAFettenxcJ2H8"><img src={mapsIcon} alt='maps_icon' className='mapsIcon'></img></a> Bonocosi - a pizzeria, open 13:00-23:00
                </li>
                <li>
                    <a href="https://maps.app.goo.gl/1ANJKX5i9AaNGA33A"><img src={mapsIcon} alt='maps_icon' className='mapsIcon'></img></a> Saigon - a vietnamese place, open 12:00-22:00
                </li>
                <li>
                    <a href="https://maps.app.goo.gl/Ck3dVWpawk5mJyUV6"><img src={mapsIcon} alt='maps_icon' className='mapsIcon'></img></a> Houdini - grilled food place, open 12:00-02:00
                </li>
            </ul>
         
            <p className='smallText'>*these places are not associated with our event, they were just chosen due to distance and variety of food provided</p>
        </div>
    )
}